.main{
	position:relative;
	@media screen and (max-width:$tablet) {
		margin-bottom:0px;
		width: 100%;
	}
	.mobile-head{
		@media screen and (max-width:$tablet) {
			display: flex; 
			flex-direction: column;
			text-align: center;		
			margin:20% 0;	
		}
		.menu {
			display:none;
			text-align: center;
			margin-bottom: 30px;
			@media screen and (max-width:$tablet) {
				display: block;
				margin: 30px 0;
			}
			li{
				width: 70%;
				margin: 20px auto;
				a{
					color:#ffffff;
					text-decoration: none;
					display: block;
					padding:12px 8px;
					@include border-radius(8px);
					@include linear-gradient(40, $third-color,$third-color); 					
				}
			}
		}
	}
	.logo {
		width: 130px;
		margin: 0 0 40px;
		@media screen and (max-width:$tablet) {
			width: 90px;
			margin: 0 0 10px;
		}
	}
	.social-icons {
		@media screen and (min-width:$tablet) {
			position:absolute;
			right:30px;
			top:30px;
		}
		a {
			text-decoration: none;
		}
		svg {
			display: inline-block;
			font-size: 20px;
			margin-left:10px;
			color: $secondary-color;
			@include transition(all, $duration);
			&:hover{
				color:$third-color;
				@include transition(all, $duration);
			}
		}
	}
	h2{
		padding:50px 0 10px;
		font-size: 24px;
	}
	img{
		margin:10px 0;
		@media screen and (max-width:$handheld){
			margin:10px 0 0;
		}
	}
}
#content {
	li{
		list-style:none;
		padding-top:45px;
	}
	h2{
		color:$third-color;
		padding: 0 0 15px 0;
		font-size:18px;
	}		
	table{
		width:100%;	
		td{
			vertical-align:top;
			img {
				margin:0;
				width:150px;
				display:block;
			}
			div + a,div{
				padding:0 0 10px 20px;
			}
			+td{
				padding-top:13px;
			}
		}		
	}
}