.col-25-gutter {
    @media screen and (min-width:$break) {
        margin-top: 20px;
        width: 23.5%;
        margin-left: 2%;
        @include box-sizing(border-box);
        &:nth-of-type(-n + 4) {
            margin-top: 0;
        }
        &:nth-of-type(4n + 1) {
            margin-left: 0;
            clear: left;
        }
  }
  @media screen and (max-width: $break){
        margin-top: 30px;
        width: 48.5%;
        margin-left: 2%;
        &:first-of-type {
            margin-left: 0;
        }
        &:nth-of-type(2n+1) {
            clear: left;
            margin-left: 0;
        }
    }
    @media screen and (max-width: $tablet){
        margin-top: 30px;
        width: 100%;
        clear: left;
        margin-left: 0;
        &:first-of-type {
            margin-top: 0;
        }
    }
}

.col-25 {
    @media screen and (min-width:$break) {
        width: 25%;
        @include box-sizing(border-box);
    }
    @media screen and (max-width: $break){
        width: 50%;
    }
    @media screen and (max-width: $handheld){
        width: 100%;
    }
}

.col-33-gutter {
    margin-top: 30px;
    width: 31%;
    @include box-sizing(border-box);
    margin-left: 3.5%;
    &:nth-of-type(-n + 3) {
        margin-top: 0;
    }
    &:nth-of-type(3n + 1) {
        margin-left: 0;
    }
}

.col-33 {
    @media screen and (min-width:$handheld) {
        width: 32%;
        margin-left:3%;
        &:nth-of-type(3n + 1) {
            /* clear: left; */
            margin-left: 0;
        }
    }
    @media screen and (max-width: $tablet){
        width: 100%;
        padding-top:30px;
        margin-left:0;
    }
}


.col-50 {
    width: 50%;
    @include box-sizing(border-box);
    @media screen and (max-width: $break) {
        width: 100%;
    }
}

.col-50-gutter {
    width: 49.4%;
    margin-left: 1.1%;
    @include box-sizing(border-box);
    &:first-of-type {
        margin-left: 0;
    }
    &:nth-of-type(2n+1) {
        clear: left;
        margin-left: 0;
    }
    @media screen and (max-width: $tablet) {
        width: 100%;
        margin-left:0;
    }
}

.col-66 {
    width: 65%;
    @media screen and (max-width: $tablet){
        width: 100%;
        padding-top:30px;
    }
}

[class*="col-"] {
  float: left;
  display: inline-block
}