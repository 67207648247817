sidebar {
	float:right !important;
	h2{
		padding-bottom:10px;
	}
	.boxed{
		margin-bottom:30px;
	}
	li,
	.lab-item{
		display: block;
		background-color:$secondary-color;
		margin-top:30px;
		text-decoration: none;
		padding:20px;
		@include border-radius(6px);
		@include transition(all, $duration);
		&:hover {
			background-color:$third-color;
			a{
				color:#ffffff;
			}
		}
		h3, p{
			color:#ffffff;
			text-align:center;
		}
		h3{
			padding-bottom: 10px;
			font-size: 19px;
		}
	}
	.interact{
		display: none;
	}
	nav{
		li {
			background-color:transparent;
			margin:15px 10px 0;
			padding:0;
			display:inline-block;
			&:first-of-type{
				margin-top:0;
			}
			a {
				text-decoration:none;
			}
			.fas{
				display:none;
			}
			svg{
				display:none;
			}
			&:hover{
				background-color:transparent;
				a {
					color:$third-color;
				}
			}			
		}
		@media screen and (max-width:$tablet){
			position:fixed;
			bottom:0;
			background-color:$third-color;
			width:103%;
			margin-left:-5%;
			a{
				color:$primary-color;
				position:relative;
				z-index:100;
				svg{
					display:block;
					font-size:20px;
					padding-bottom:5px;
					margin:0 auto;
				}
			}
			li{
				display:inline-block;
				width:50%;
				float:left;
				margin:0;
				text-align:center;
				padding:5px 0;
				font-size:12px;
				@include border-radius(0);
				border-left:1px solid $primary-color;
				@include box-sizing(border-box);
				&:first-of-type{
					border:none;
				}
			}
		}
	}
}