a {
	color:$third-color;
}

em {
	font-style:italic;
}

::-moz-selection { 
	background: $third-color; 
	color:$secondary-color;
}
::selection { 
	background: $third-color; 
	color:$secondary-color;
}

.wrapper {
	position:relative;
	width:1200px;
	margin:40px auto;
	overflow: hidden;
	@media screen and (max-width:1240px) {
		width:90%;
		padding:0 5%;
	}
	@media screen and (max-width:$handheld){
		margin: 10px 0 0;
		width: 96%;
		padding:0 2%;
	}
}

section{
	clear:both;
	width:100%;
}

body{
	min-height:600px;
	background-color:#ffffff;
}

.boxed {
	@include box-sizing(border-box);
	border:2px solid $primary-color;
	padding:30px;
	@include border-radius(6px);
	@media screen and (max-width:$handheld){
		padding:15px;
	}
	h1{
		color:$third-color;
		padding-bottom:14px;
		img {
			width:70px;
			margin: 0;
		    vertical-align: top;
		}
	}
	img {
		max-width: 100%;
		width: 100%;
	}
}
.btn-cont{
	margin:30px 0;
	display: block;
}
.btn {
	padding:10px;
	color:#fff;
	background:$third-color;
	text-decoration: none;
}