strong {
	font-weight:bold;
}

h1,h2,h3,h4,h5,h6,p,li,a{
	font-family:$primart-font;
}

h1 {
	font-size:30px;
}

h2 {
	font-size:18px;
	font-weight:600;
}

p{
	line-height:26px;
}