article {
	font-family:$primart-font;
	//titles
	.title{
		text-align: center;
		margin:0 0 80px;
	}
	h1,h2,h3,h4,h5,h6 {
		padding-bottom: 10px;
		text-transform: initial;
		font-weight:500;
	}
	h2{
		font-size: 36px;
		line-height: 46px;
		padding-bottom: 10px;
		margin-top:30px;
		@media screen and (max-width:$handheld){
			font-size: 18px;
			line-height: 24px;
		}		
	}
	h3 {
		font-size: 22px;
		line-height: 34px;
		margin-top:30px;
		padding-bottom: 10px;
		@media screen and (max-width:$handheld){
			font-size: 22px;
			line-height: 36px;
		}
	}
	h4{
		font-size: 22px;
		line-height: 37px;
		font-weight: 500;
	}
	h5,li, p{
		font-size: 18px;
		line-height: 36px;
	}	
	p,ul,ol {
		padding-bottom: 25px;
		color:#000;
	}
	ol,ul{
		margin-left:30px;
		list-style: disc;
		position: relative;	
	}
	ol{
		list-style-type:decimal;
	}
	ul {
		list-style: disc;
		position: relative;
		li:before{
			content:'•';
			font-size:26pt; 
			left: -23px;
			color:$primary-color;
			position: absolute;
		}
	}
	a {
		color:darken($primary-color,35%);
		text-decoration: underline;
	}
	hr{
		height: 1px;
		width: 100%;
		clear: both;
		background-color: $primary-color; 
		opacity: .2;
		border: none;
		display: block;
		background-position:center;
		background-repeat:no-repeat;
    	margin-bottom: 24px;
    	display: block;
	}
	b, strong {
		font-weight: 600;
	}
	//////////////////////////////// Basic Blockquote
	// blockquote {
	// 	margin:0 0 30px;
	// 	padding:5px 0 5px 40px;
	// 	border-left:5px solid $primary-color;
	// 	p {
	// 		padding-bottom:0;
	// 		font-style: italic;
	// 	}
	// }

	//////////////////////////////// Large and Blockquote

	blockquote {
		padding:20px;
		p {
			line-height: 40px;
			font-style: italic;
			font-size: 25px;
			padding-bottom: 10px;
			&:last-of-type{
				font-size: 20px;
			}
		}
	}
	img {
		max-width: 100%;
		height: auto;
		@include transition(all,$duration);
	}	
	.wp-caption{
		@media screen and (max-width:$tablet){
			width: auto !important;
		}
	}
	.alignright{
		float: right;
		margin:0 0 0 15px;
		&[sizes*="max-width"],
		&.size-full{
			padding:0px 30px 0px 0;
		}		
	}
	.alignleft{
		float: left;
		margin:0 15px 0 0;
		&[sizes*="max-width"],
		&.size-full{
			padding:0px 30px 0px 0;
		}
	}
	.aligncenter{
		margin:20px auto;
		display: block;
	}	
	.wp-caption-text{
		font-size: 15px;
		font-style: italic;
	}
	iframe{
		max-width:100%;
		video{
			position:absolute;
			width:100%;
			height:100%;
		}
	}
	.mobile-block{
		margin:0 20px;
		@media screen and (max-width:$handheld){
			display: block;
		}
	}
	//Twitter Feed
	#ctf{
		img{
			opacity: 1 !important;
		}
		a {
			border:none;
		}				
		.ctf-header-img-hover{
			opacity: 1 !important;
			display: block !important;
		}
	}
	#ctf #ctf-more.ctf-more{
		display: block;
		width: 122px;
		border-radius:0;
		margin: 20px  auto 0;	
	}
}
