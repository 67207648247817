.portfolio{
	.description {
		padding-bottom:20px;
	}
	.col-50-gutter{
		margin-bottom: 30px;
		.overlay {
			height: 190px;
			background-position: center;
			background-size:cover;
		}
		h5{
			padding-top:13px;
			text-decoration: none;
		}
	}
}