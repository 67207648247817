///Break Points
$grid: 1400px;
$break: 1024px;
$tablet: 768px;
$handheld: 600px;
$mobile: 400px;
$iphone5: 320px;

///Fonts
$primart-font: 'Oxygen', sans-serif;
$secondary-font: "Times New Roman", Times, serif;

///Colors
$primary-color:#CFF09E; //green
$secondary-color:#79BD9A; // greenblue
$third-color:#0B486B; //blue

///Animation 
$duration:200ms;
$duration-long:700ms;